import { reactLocalStorage } from "reactjs-localstorage";
import { getTotalDuration } from "./utils";
export function SendGuiDataEvents(data) {
    // if (new Date().getTime() - sessionStorage.getItem("lastPublish") > 300000) {
    //   //5mins
    //   sessionStorage.setItem("sessionStartTime", new Date().getTime());
    // }
    // sessionStorage.setItem("lastPublish", new Date().getTime());
    // let msisdn = "na";
    // if (localStorage.getItem("tempObj")) {
    //   const { number, code } = JSON.parse(localStorage.getItem("tempObj"));
    //   msisdn = code + number;
    // }
    // var userProfile = "";
    // if (localStorage.getItem("userProfile")) {
    //   userProfile = JSON.parse(localStorage.getItem("userProfile"));
    // }
    if((new Date().getTime() - sessionStorage.getItem("lastPublish")) > 300000){  //5mins
        sessionStorage.setItem("sessionStartTime", new Date().getTime()) 
    }
    

  if (!localStorage.getItem("randomNumber")) {
    //generate 5 digit random no
    const min = 10000;
    const max = 99999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("----- random number ----->", randomNumber);
    localStorage.setItem("randomNumber", randomNumber);
  }
  //----> session id generate logic
  var sessionTime = localStorage.getItem("sessionTime")
    ? localStorage.getItem("sessionTime")
    : "";
  var sessionId = localStorage.getItem("sessionId")
    ? localStorage.getItem("sessionId")
    : "";
  if (sessionTime && sessionId && Date.now() - sessionTime > 300000) {
    // 5 min
    console.log(
      "----- session id updated : --->" +
        Date.now() +
        "" +
        localStorage.getItem("randomNumber")
    );
    const a = Date.now() + "" + localStorage.getItem("randomNumber");
    localStorage.setItem("sessionId", a);
    localStorage.setItem("user_type", "repeatedUser"); // repeated user
  }
  sessionTime = Date.now();
  if (sessionId === "") {
    //set session id for first time only
    sessionId = sessionTime + "" + localStorage.getItem("randomNumber");
    localStorage.setItem("sessionId", sessionId);
  }
  localStorage.setItem("sessionTime", sessionTime);  
 // ----> generate device id
var deviceId = localStorage.getItem("device_id")
? localStorage.getItem("device_id")
: "";
if (deviceId === "") {
// milli seconds till generate
deviceId = Date.now() + "" + localStorage.getItem("randomNumber");
console.log("----- device id set : --->" + deviceId);
localStorage.setItem("device_id", deviceId);
}

// ----> msisdn calc 
console.log("uid and msisdn",reactLocalStorage.get("uid")    ,reactLocalStorage.get("msisdn"))
const guiDataEvent = {
    operator_id: "afghanistan_mtn",
    domain: window.location.hostname,
    user_agent: navigator.userAgent,
    uid: reactLocalStorage.get("uid") ? reactLocalStorage.get("uid") :'NA',
    msisdn: reactLocalStorage.get("msisdn")
    ? reactLocalStorage.get("msisdn")
    :'NA' ,

    device_type: "web",
    previouspage: "na",
    session_id:localStorage.getItem('sessionId'),
    channel:localStorage.getItem('channel')?localStorage.getItem('channel'):"wap",
    trace: "",
    request_time: new Date().toISOString(),
    aon_time: getTotalDuration("local"),
    session_time_duration: getTotalDuration("session"),
    session_start_time: sessionStorage.getItem("sessionStartTime"),
    referrer_info: "na",
    request_time: new Date().toISOString(),
  };
  
  let tracer = reactLocalStorage.get("tracer")
  ? JSON.parse(reactLocalStorage.get("tracer"))
  : "";
guiDataEvent["previouspage"] = tracer[tracer.length - 1];
guiDataEvent["sub_event"] = data["page"] + "_" + data["event"];
tracer = tracer + data["page"] + "_" + data["event"] + "|";
reactLocalStorage.set("tracer", JSON.stringify(tracer));
guiDataEvent["trace"] = tracer;
  let objData = { ...guiDataEvent, ...data };
  var jsonData_base64 = window.btoa(JSON.stringify(objData)); //base_64

//   let url = config.get('elastic_base_url');
let url ="https://serviceusagesv1.bngrenew.com/publish";
    fetch(url, { method: "POST", headers: {"guiTopic": "education_portal"} , body: jsonData_base64 })
      .then((resp) => {
        console.log("resp ==>>>", resp);
      })
      .catch((error) => {
        console.log("error", error);
      });

  console.log("Object data event object ---->", objData);
  console.log("Object data event object base64 ---->", jsonData_base64);
}
