import React from "react";
import {
  makeStyles,
  Slider,
  withStyles,
  Button,
  Tooltip,
  Popover,
  Grid,
} from "@material-ui/core";
import {
  FastForward,
  FastRewind,
  SkipNext,
  VolumeUp,
  VolumeOff,
} from "@material-ui/icons";
import "./Control.css";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";

const useStyles = makeStyles({
  volumeSlider: {
    width: "70px",
    color: "#fff",
    // backgroundColor: "#d11d59",
    // marginLeft: 2,
    border: "1px solid #000",
    marginRight: 4,
  },

  bottomIcons: {
    color: "#999",
    padding: "12px 8px",

    "&:hover": {
      color: "#fff",
    },
  },
});


const PrettoSlider = withStyles({
  root: {
    height: "20px",
    color: "#ffcc00",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "88%",
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#ffcc00",
    border: "2px solid #fff",
     marginTop: -3,
    marginLeft: -6,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 5,
    borderRadius: 4,
    width: "100%",
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);


const PrettoSliderVol = withStyles({
  root: {
    height: "10px",
    color: "#ffcc00",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    width: "88px",
  },
  thumb: {
    height: 13,
    width: 13,
    backgroundColor: "#ffcc00",
    // marginTop: -3,
    marginLeft: 6,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 4,
    borderRadius: 3,
    width: "100%",
  },
  rail: {
    height: 4,
    borderRadius: 3,
  },
})(Slider);

const Control = ({
  onPlayPause,
  playing,
  onRewind,
  onForward,
  played,
  onSeek,
  onSeekMouseUp,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  volume,
  mute,
  onMute,
  duration,
  currentTime,
  onMouseSeekDown,
  controlRef,
  enterFullScreen,
  entereExitFullScreen,
  isFullScreen,
}) => {
  const classes = useStyles();

  return (
    <div className="control_Container" ref={controlRef}>
      <div className="top_container bg-pink-200">
        {/* <h2>Video PLayer</h2> */}
      </div>
      <div
        className="bottom__container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        

        <div className="flex justify-start">
          <p className="text-white md:mt-2 mt-8  ml-2 font-semibold">{currentTime}</p>

          <PrettoSlider
            min={0}
            max={100}
            value={played * 100}
            onChange={onSeek}
            onChangeCommitted={onSeekMouseUp}
            onMouseDown={onMouseSeekDown}
          />
          <p className="text-white md:mt-2 mt-8  mx-4 font-semibold">{duration}</p>
        </div>

        
        <div className="flex justify-between px-4">
          <div className="flex flex-row md:mt-1">
            <div className="icon__btn_volume" onClick={onMute}>
              {mute ? (
                <VolumeOff fontSize="medium" />
              ) : (
                <VolumeUp fontSize="medium" />
              )}
            </div>

            <PrettoSliderVol
              onChange={onVolumeChangeHandler}
              value={volume * 100}
              onChangeCommitted={onVolumeSeekUp}
            />
          </div>

          <div className="flex justify-start mr-20">
            <div className="inner__controls">
              <div className="icon__btn" onClick={onRewind}>
                <FastRewind fontSize="medium" />
              </div>
              <div className="icon__btn" onClick={onPlayPause}>
                {playing ? (
                  // <Pause fontSize="medium" />
                  <FaIcons.FaPause />
                ) : (
                  <FaIcons.FaPlay />
                  // <PlayArrow fontSize="medium" />
                )}
              </div>
              <div className="icon__btn">
                <FastForward fontSize="medium" onClick={onForward} />
              </div>
            </div>

            {/* <div className="icon__btn">
              <SkipNext fontSize="medium" />
            </div> */}
          </div>

          <div className="flex justify-start">
            {!isFullScreen ? (
              <MdIcons.MdOutlineFullscreen
                size={28}
                color="#fff"
                className="font-bold md:mt-1 mt-4"
                onClick={entereExitFullScreen}
              />
            ) : (
              <MdIcons.MdOutlineFullscreenExit
                size={28}
                color="#fff"
                className="font-bold mt-2"
                onClick={entereExitFullScreen}
              />
            )}

            {/* <div className="icon__btn">
              <SkipNext fontSize="medium" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Control;
