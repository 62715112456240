// import React from 'react';

export const navtxt = {
  home: {
    en: "Home",
    fa: "صفحه اصلی",
    ps: "اصلی پاڼه",
  },
  about: {
    en: "About Us",
    fa: "درباره ما",
    ps: "زموږ په اړه",
  },
  catg: {
    en: "Category",
    fa: "دسته بندی",
    ps: "کټګوري",
  },
  type: {
    en: "Type",
    fa: "انواع",
    ps: "ډول",
  },
  lang: {
    en: "Language",
    fa: "زبان",
    ps: "ژبه",
  },
  eng: {
    en: "English",
    fa: "انگلیسی",
    ps: "انګلیسي",
  },
  dari: {
    en: "Dari",
    fa: "دری",
    ps: "دري",
  },
  pashto: {
    en: "Pashto",
    fa: "پشتو",
    ps: "پښتو",
  },
  trendingitems: {
    en: "Trending Items",
    fa: "مورد پرطرفدار",
    ps: "غوره شوي توکي",
  },
  aboutdata: {
    en: "The MTN Education Portal is an online store that aims to support a healthy culture. The MTN Education Portal is a place where the author can communicate with the readers and we get to know the author.Where children get involved in the excitement of reading that can follow them for a lifetime and be useful for a beneficial society. The book provides you with digital access to books, videos, audiobooks, notes, and other useful items. which enriches your sense of curiosity and modern culture.We are committed to providing you with the best useful and useful resources through the booking window.",
    fa: "به دریچه آموزشی ام تی ان خوش آمدید: دریچه آموزشی ام تی ان یک منبع انترنتی بوده که هدف آن حمایت از یک فرهنگ سالم، معرفت و مطالعه میباشد. دریچه آموزشی ام تی ان مکانی است که نویسنده گان می توانند با خوانندگان ارتباط قایم نمایند و ما با نویسنده آشنا شویم. جایی که کودکان هم می توانند با مطالعه کتاب های سودمند و هیجانی به جامعه مفيد ببارآیند. خواندن وآموزش میتواند یک عمر ادامه داشته باشد و برای یک جامعه سودمند، از آنجایکه دسترسی دیجیتالی به کتاب ها، ویدیوها، کتاب های صوتی، یادداشت ها و دیگر موارد مفید ضروری است این خدمت برای شما همه این را فراهم میکند. بنآ این ىريچه میتواند باعث غنا مندی، حس کنجکاوی و فرهنگ مدرن شما درجامعه گردد.   ما متعهد هستیم تا بهترین منابع مفید وسود مند را از طریق دریچه آموزشی ام تی ان برای شما ارایه نماییم",
    ps: "د ام ټی ان تعلیمی پورټل ته ښه راغلاست: د ام ټی ان تعلیمی پورټل یو آنلاین سرچینه ده چې هدف یې د سالم کلتور ملاتړ، پوهه او اړینه مطالعه ده. د ام ټی ان تعلیمی پورټل هغه تګ لاره دی چې لیکوالان کولای شي له لوستونکو سره اړیکه ټینګه کړي او مونږ لیکوالان وپیژنو،او هغه ځای کی چی ماشومان کولای شی مطالعه وکړی او ټولنی ته ګټور او سو دمند وړاندی شی، همدا راز دوی کولای شی چی کتابونو، ویډیوګانو او غږيزه کتابونو ته ډیجیټلی لاسرسی ولري. د ام ټی ان تعلیمی پورټل ستاسو لپاره عصری او ګټورکتابونه ، ویډیوګانی ، غږيزه کتابونه او نورګټورتعلیمی موارد وړاندی کوی. مونږ ژمن یو چې تاسو ته د ام تی ان تعلیمی پورټل له لارې غوره او ګټورې سرچینې چمتو کړو.",
  },
  welcometxt: {
    en: "Welcome to MTN Education Portal!",
    fa: "!به پورتل تعلیمی ام تی ان خوش آمدید",
    ps: "!د ام ټي ان ښوونیز پورټل ته  ښه راغلاست",
  },
  allbooks: {
    en: "All Books",
    fa: "تمام کتاب ها",
    ps: "ټول کتابونه",
  },
  welcometo: {
    en: "Welcome To ",
    fa: "خوش آمدید",
    ps: "د ام ټي ان ښوونیز پورټل ته",
  },
  edportal: {
    en: "MTN Education Portal!",
    fa: "به پورتل تعلیمی ام تی ان!",
    ps: "ښه راغلاست!",
  },
  p1: {
    en: "It’s an Education Portal that aims to support a healthy culture. MTN Education Portal is a place where the author can communicate with the readers and help enhance the reading and learning culture,",
    fa: "این یک پورتل تعلیمی است که هدف آن حمایت از یک فرهنگ سالم میباشد. از طریق پورتل تعلیمی ام تی ان نویسنده میتواند با خوانندگان ارتباط برقرار نماید و به تقویت فرهنگ مطالعه و آموزش کمک کند.",
    ps: "دا یو ښوونیز پورټل دی چې موخه یې له سالم کلتور څخه ملاتړ کول دي. د ام ټي ان د ښوونیز پورټل له لارې لیکوال کولای شي له لوستونکو سره اړیکه ټینګه کړي او د لوستلو او زده کړو کلتور له ودې سره مرسته وکړي.",
  },
  p2: {
    en: "Where children get involved in the excitement of reading that can follow them for a lifetime and be useful for a beneficial society. The Portal provides you with digital access to online Books, Videos, Audio Books, Audio Notes, and other useful learning contents which enriches your sense of curiosity and modern culture. We are committed to provide you with the best useful learning resources through the portal.",
    fa: "در اینجا اطفال میتوانند با علاقه مندی در خواندن دخیل شوند، در طول عمر این کار را ادامه دهند و برای جامعه مفید واقع شوند. این پورتل برای شما زمینه فراهم می سازد تا به کتاب ها، ویدیوها، کتاب های صوتی، پیام های کوتاه صوتی آنلاین و سایر مطالب مفید بطور الکترونیکی دسترسی داشته باشید که حس کنجکاوی و فرهنگ عصری شما را غنی میسازد. ما متعهد هستیم که از طریق این پورتل منابع مفید تعلیمی را برای شما فراهم سازیم.",
    ps: "دلته ماشومان کولای شي په خورا لیوالتیا سره په لوستلو کې بوخت و اوسي، د ژوند په اوږدو کې دې کار ته دوام ورکړي او د ټولنې لپاره ګټور تمام شي. دا پورټل تاسو ته په ډیجیټل ډول آنلاین کتابونو، ویډیوګانو، غږیزو کتابونو، لنډو غږیزو پیغامونو او نورو ګټورو ښوونیزو مطالبو ته د لاسرسي زمینه برابروي، چې په مرسته یې ستاسو د پلټنې حس او عصري کلتور بډایه کوي. موږ ژمن یو چې د پورټل له لارې تاسو ته تر ټولو ګټورې ښوونیزې سرچینې چمتو کړو.",
  },
  unsubscribe: {
    en: "Unsubscribe",
    fa: "لغو اشتراک",
    ps: "د گډون لغوه",
  },
  unsubtext: {
    en: "Are you sure want to proceed?",
    fa: "آیا مطمئن هستید که می خواهید ادامه دهید؟",
    ps: "ایا تاسو ډاډه یاست چې مخ په وړاندې لاړ شئ؟",
  },
  no: {
    en: "No",
    fa: "نخیر",
    ps: "نه",
  },
  yes: {
    en: "Yes",
    fa: "بلی",
    ps: "هو",
  },
  ok: {
    en: "Ok",
    fa: "درست است",
    ps: "سمه ده",
  },
  Books: {
    en: "Books",
    fa: "کتاب ها",
    ps: "کتابونه",
  },
  Videos: {
    en: "Videos",
    fa: "ویدیو",
    ps: "ویډیو",
  },
  Audios: {
    en: "Audios",
    fa: "صوتی",
    ps: "غږیزه",
  },
  viewmore: {
    en:"View More",
    fa:"مشاهده بیشتر",
    ps:"نور وګوره"
  },
  comingsoon: {
    en:"Coming Soon",
    fa:"به زودی",
    ps:"ژر راځي"
  },
};
