import React, { useState, useEffect, useRef } from "react";
import AudioControls from "./AudioControls";
// import Backdrop from "./Backdrop";
import "./Audio.css";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */
const AudioPlayer = ({ tracks }) => {
  let leftTextAudio = "00:00";
  let rightTextAudio = "00:00";

  // State
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // Destructure for conciseness
  const {
    title,
    artist,
    image,
    audioSrc,
  } = tracks[trackIndex];

  // Refs
  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();
  //   const isReady = useRef(false);
  const [isReady, setIsReady] = useState(false);
  // Destructure for conciseness
  const { duration } = audioRef.current;
  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #f8ce46), color-stop(${currentPercentage}, #777))
  `;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
        setIsPlaying(false);
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  // const toPrevTrack = () => {
  //   if (trackIndex - 1 < 0) {
  //     setTrackIndex(tracks.length - 1);
  //   } else {
  //     setTrackIndex(trackIndex - 1);
  //   }
  // };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();
    audioRef.current = new Audio(audioSrc);
    setTrackProgress(audioRef.current.currentTime);
    if (isReady) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      setIsReady(true);
    }
  }, [trackIndex]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  const rewind5Seconds = () => {
    const val = audioRef.current.currentTime - 5;
    audioRef.current.currentTime = audioRef.current.currentTime - 5;
    setTrackProgress(val);
  };

  const forward5Seconds = () => {
    const val = audioRef.current.currentTime + 5;
    audioRef.current.currentTime = audioRef.current.currentTime + 5;
    setTrackProgress(val);
  };

  let secndLeft = audioRef.current.currentTime;
  let mnteLeft = secndLeft / 60;
  let remnderLeft = secndLeft % 60;

  let secndRight = duration;
  let mnteRight = duration && secndRight / 60;
  let remnderRight = duration && secndRight % 60;

  if (secndLeft < 58) {
    if (secndLeft < 10) {
      leftTextAudio = `00:0${parseInt(secndLeft)}`;
    } else {
      leftTextAudio = `00:${parseInt(secndLeft)}`;
    }
  } else if (mnteLeft < 10) {
    if (remnderLeft < 10) {
      leftTextAudio = `0${parseInt(mnteLeft)}:0${parseInt(remnderLeft)}`;
    } else {
      leftTextAudio = `0${parseInt(mnteLeft)}:${parseInt(remnderLeft)}`;
    }
  } else if (mnteRight > 10 && mnteRight < 60) {
    if (remnderLeft < 10) {
      leftTextAudio = `${parseInt(mnteLeft)}:0${parseInt(remnderLeft)}`;
    } else {
      leftTextAudio = `${parseInt(mnteLeft)}:${parseInt(remnderLeft)}`;
    }
  }

  if (duration) {
    if (secndRight < 58) {
      if (secndRight < 10) {
        rightTextAudio = `00:0${parseInt(secndRight)}`;
      } else {
        rightTextAudio = `00:${parseInt(secndRight)}`;
      }
    } else if (mnteRight < 10) {
      if (remnderRight < 10) {
        rightTextAudio = `0${parseInt(mnteRight)}:0${parseInt(remnderRight)}`;
      } else {
        rightTextAudio = `0${parseInt(mnteRight)}:${parseInt(remnderRight)}`;
      }
    } else if (mnteRight > 10 && mnteRight < 60) {
      if (remnderRight < 10) {
        rightTextAudio = `${parseInt(mnteRight)}:0${parseInt(remnderRight)}`;
      } else {
        rightTextAudio = `${parseInt(mnteRight)}:${parseInt(remnderRight)}`;
      }
    }
  }

  return (
    <div className="audio-player">
      <div className="track-info">
        <img
          className="artwork"
          src={image}
          alt={`track artwork for ${title} by ${artist}`}
        />
        <h2 className="title mt-3">{title}</h2>
        {/* <h3 className="artist mt-3">{artist}</h3> */}
        <AudioControls
          isPlaying={isPlaying}
          onPrevClick={rewind5Seconds}
          onNextClick={forward5Seconds}
          onPlayPauseClick={setIsPlaying}
        />
        <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress mt-3"
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        />
        <div className="flex justify-between px-1">
          <p className="text-white font-semibold text-base">{leftTextAudio}</p>
          <p className="text-white font-semibold text-base">{rightTextAudio}</p>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
