import React from 'react';
import { chngimg } from './imagechng.js'
//import banner from '../pics/banner.png'

const Hero = () => {
    const lang = localStorage.getItem("lang")
    return (
        <div className='md:mt-10 mt-12'>
            <img className=' w-screen   bg-cover contain' alt='/' src={chngimg.img1[lang]}></img>
        </div>
    )
}
export default Hero;