import React from "react";

const BlankNavbar = () => {
  return (
    <div className="flex justify-evenly pb-1 bg-yellow max-w-screen h-12 top-0 absolute w-full md:z-100">
    </div>
  );
};

export default BlankNavbar;
