import React, { useContext } from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import BookInfo from "./BookInfo";
import Button from "./Buttons";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import config from "./config";
import {
  allAudios,
  allBooksCategoryEn,
  allVideos,
} from "../Databases/Database";
import { CommonContext } from "../Contexts/CommonStates";
import Modal from "react-modal";
import { navtxt } from "./text.js";
import loader from "../assets/loading.gif";
import axios from "axios";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import GaHandler from "./Utilities";
import BlankNavbar from "./BlankNavbar";
import types from "./types.json";
import { SendGuiDataEvents } from "../commonScript";

const Home = () => {
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const {
    setIsCategoryOpen,
    currentSelectedType,
    setCurrentSelectedCategory,
    allCategoriesBooks,
    setAllCategoriesBooks,
    allBooks,
    setAllBooks,
    currentSelectedCategory,
    setSelectedInfo,
    setCurrentSelectedType,
  } = useContext(CommonContext);
  const { setIsTypeOpen } = useContext(CommonContext);
  const { setIsLangOpen } = useContext(CommonContext);
  const [loading, setLoading] = useState(false);
  // const location = useLocation();
  const lang = localStorage.getItem("lang");
  const [showVideos, setShowVideos] = useState(4);
  const [showAudios, setShowAudios] = useState(4);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [loading2, setLoading2] = useState(false);
  const [audios, setAudios] = useState([]);
  const [videos, setVideos] = useState([]);
  const { search } = useLocation();
  const { ln, uid } = queryString.parse(search);
  const version = 1.0;
  // const { changeLanguage } = useState(CommonContext);
  const checksub = (uid) => {
    setLoading(true);
    const getLang = localStorage.getItem("lang");
    const url = `${config.get("base")}${config.get("checkSub")}`;
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        uid: uid,
        msisdn: 701701701,
        mcc: 611,
        mnc: "04",
      }),
      headers: config.get("headers"),
    })
      // console.log("checksub req",uid)
      .then((res) => {
        // console.log(res)
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (result) => {
          console.log("result.ln",result.ln)
          if (!getLang && !ln) {
            console.log("enteredd in checksub lang");
            localStorage.setItem("lang", result.ln);
          }
          localStorage.setItem("msisdn", result.msisdn);
          if (result.status.toUpperCase() === "FAILED") {
            // console.log("status failed log ----------------------->");
            const sub = localStorage.getItem("suburl");
            sub && window.location.replace(sub, "_self");
            // console.log("entered in then. result");
          }
          setLoading(false);
          GaHandler("api call", "checkSub", "event working");
          GaHandler("subStatus", result.subStatus, "subStatus event sent");
          const guiEvents = {};
          guiEvents['page'] = "home";
          guiEvents['event'] = "checksub_request";
          guiEvents['status'] = result.status
          SendGuiDataEvents(guiEvents);
        },

        (error) => {
          console.log("error:::", error);
        }
      );
  };
  useEffect(() => {
    // console.log("first use effect");
    // const IniatialVerison = localStorage.getItem("version");
    // if (!IniatialVerison) {
    //   localStorage.setItem("version", 0);
    // }
    // console.log(uid);
    // if (uid) {
    //   window.localStorage.setItem("uid", uid);
    // }
    const getUID = window.localStorage.getItem("uid")
      ? window.localStorage.getItem("uid")
      : null;

    if (getUID) {
      checksub(getUID);
    } else {
      const sub = localStorage.getItem("suburl");
      sub && window.location.replace(sub, "_self");
      // console.log("entered in else")
    }
  }, []);

  const handleUnsub = () => {
    const uid = localStorage.getItem("uid");
    setLoading(true);
    // console.log("Unsub called");
    const url = `${config.get("base")}${config.get("unsubscribe")}`;
    // console.log("unsub req : ", JSON.stringify({ uid: uid }));

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        uid: uid,
        msisdn: 701701701,
        mcc: 611,
        mnc: "04",
      }),
      headers: config.get("headers"),
    })
      // console.log("checksub req",uid)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("result message::", result);
          // console.log("uid is set", uid);
          setLoading(false);

          if (result.status === "failed") {
            setMsg(result.message);

            closeModal();
            setIsOpen2(true);
            // console.log("unsub failed-------->");
            // console.log("unsub message--->", msg);
          }
          if (result.status.toUpperCase() === "SUCCESS") {
            // checksub(uid);
            // var msg= result.message;
            // console.log(msg);
            setMsg(result.message);
            // openModal();
            closeModal();
            setIsOpen2(true);
          }
        }
        // (error) => {
        //   console.log("error:::", error);
        //   setLoading(false);
        // }
      )
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(()=>{
      if(uid){
        {
          let guiEvents={};
          guiEvents['page']="home";
          guiEvents["event"] = "open";
          guiEvents['langugae'] = localStorage.getItem("lang");
          SendGuiDataEvents(guiEvents);
          }
      }
  },[])

  async function fetchData(uid) {
    setLoading2(true);
    const response = await axios({
      method: "post",
      url: "https://digitalweb.bngrenew.com/api/dataApi",
      data: {
        uid: uid,
        msisdn: 701701701,
        mcc: 611,
        mnc: "04",
      },
      headers: config.get("headers"),
    });
    setAllBooks(response.data);
    setLoading2(false);
    // console.log(
    //   "api data response from fetchData function  -----> ",
    //   response.data
    // );
  }

  async function getAudioVideoCategories() {
    console.log("audio video api calling ------>");
    const response = await axios({
      method: "post",
      url: "https://digitalweb.bngrenew.com/api/audiovideocategory",
      data: {
        // uid: uid,
        msisdn: 701701701,
        mcc: 611,
        mnc: "04",
      },
      headers: config.get("headers"),
    });
    if (response.data && Object.keys(response.data).length > 0) {
      console.log("audio video api resp ------>", response.data);
    }
  }

  async function categoryApiData(uid) {
    let headerss = { ...config.get("headers") };
    headerss["type"] = localStorage.getItem("type") ? localStorage.getItem("type"):"Books"
    // setLoading2(true);
    const response = await axios({
      method: "post",
      url: "https://digitalweb.bngrenew.com/api/categoryApi",
      data: {
        uid: uid,
        msisdn: 701701701,
        mcc: 611,
        mnc: "04",
      },
      headers: headerss,
    });
    if (response.data && Object.keys(response.data).length > 0) {
      console.log(response.data.categoryEn[0].en,"response----")
      setAllCategoriesBooks(response.data);
      if (localStorage.getItem("lang") === "en") {
        localStorage.setItem("currentCategory", response.data.categoryEn[0].en);
        if (!currentSelectedCategory) {
          localStorage.setItem(
            "currentCategory",
            response.data.categoryEn[0].en
          );
          setCurrentSelectedCategory(response.data.categoryEn[0].en);
        //   let guiEvents={};
        //   guiEvents['page']="home";
        //  guiEvents["event"] = "category_selected";
        //  guiEvents["category_selected"]= localStorage.getItem("currentCategory")
        //  guiEvents['langugae'] = localStorage.getItem("lang");
        //  SendGuiDataEvents(guiEvents);
        } else {
          setCurrentSelectedCategory(localStorage.getItem("currentCategory"));
        }
      }
      if (localStorage.getItem("lang") === "fa") {
        localStorage.setItem("currentCategory", response.data.categoryFa[0].en);
        if (!currentSelectedCategory) {
          localStorage.setItem(
            "currentCategory",
            response.data.categoryFa[0].en
          );
          setCurrentSelectedCategory(response.data.categoryFa[0].en);
        } else {
          setCurrentSelectedCategory(localStorage.getItem("currentCategory"));
        }
      }
      if (localStorage.getItem("lang") === "ps") {
        localStorage.setItem("currentCategory", response.data.categoryPs[0].en);
        if (!currentSelectedCategory) {
          localStorage.setItem(
            "currentCategory",
            response.data.categoryPs[0].en
          );
          setCurrentSelectedCategory(response.data.categoryPs[0].en);
        } else {
          setCurrentSelectedCategory(localStorage.getItem("currentCategory"));
        }
      }
    }
  }

  useEffect(() => {
    let selectedInformation =
      allBooks &&
      Object.keys(allBooks).length > 0 &&
      allBooks.allBooks.filter((item) => {
        if (
          item.categoryName === currentSelectedCategory &&
          item.lang === lang
        ) {
          // console.log("item----->", item);
          return item;
        } else {
          return !item;
        }
        // }
      });
    // console.log("selectedInformation in bookinfo", selectedInformation);
    setSelectedInfo(selectedInformation);
  }, [allBooks]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/home",
      title: "Home Page visit",
    });
    //   ReactGA.send(location.pathname + location.search );
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
    content: {
      top: "30%",
      left: "20%",
      height: "300px",
      width: "280px",
      // right: 'auto',
      // bottom: 'auto',
      transform: "translate(-50%, -50%)",

      color: "#3d3d3d",
      outline: "none",
      marginTop: "10%",
      marginLeft: "32%",
      padding: "0px",
    },
  };

  let subtitle;

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  // const sorryTextsForVideos = [
  //   {
  //     en: "Sorry, no Videos found! :(",
  //     fa: "بخښنه غواړئ، هیڅ ویډیو ونه موندل شوه! :(",
  //     ps: "ببخشيد، هيچ ويديو پيدا نشد! :(",
  //   },
  // ];

  function getChecksub() {
    const uid = window.localStorage.getItem("uid");
    checksub(uid);
    setLoading(false);
    setIsOpen2(false);
  }

  const showMoreVideos = () => {
    setShowVideos((prevValue) => prevValue + 4);
  };

  const showMoreAudios = () => {
    setShowAudios((prevValue) => prevValue + 4);
  };

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    fetchData(uid);
    categoryApiData(uid);
    //  getAudioVideoCategories();
}, [localStorage.getItem("type"),lang]);

  useEffect(() => {
    if (currentSelectedType === "Videos" && allBooks && allBooks.allVideos) {
      let videosLangSepecific = allBooks?.allVideos.filter(function (el) {
        return el.lang === lang;
      });
      setVideos(videosLangSepecific);
      setShowVideos(4);
      // console.log("all videos in useEffect for filter ---->",videosLangSepecific)
    } else if (
      currentSelectedType === "Audios" &&
      allBooks &&
      allBooks.allAudios
    ) {
      let audiosLangSepecific = allBooks?.allAudios.filter(function (el) {
        return el.lang === lang;
      });
      setAudios(audiosLangSepecific);
      setShowAudios(4);
      // console.log("all audios in useEffect for filter ---->",audiosLangSepecific)
    }
  }, [localStorage.getItem("lang"), localStorage.getItem("type"),allBooks]);

  useEffect(()=>{
    setShowAudios(4);
    setShowVideos(4);
  },[localStorage.getItem("currentCategory")])

  return (
    <div>
      {loading ? (
        <div className="bg-yellow flex h-screen justify-center items-center text-white">
          {/* <div>Loading</div> */}
          <img src={loader}></img>
        </div>
      ) : (
        <div className="mb-12">
          {/* <Navbar setIsOpen={setIsOpen} /> */}
          {!modalIsOpen && !modalIsOpen2 ? (
            <Navbar setIsOpen={setIsOpen} />
          ) : (
            <BlankNavbar />
          )}
          {/* <BlankNavbar /> */}
          <div
            onClick={() => {
              setIsCategoryOpen(false);
              setIsTypeOpen(false);
              setIsLangOpen(false);
            }}
          >
            <div>
              <Modal
                isOpen={modalIsOpen2}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className=" mt-8 py-6  mx-8 ">
                  <div className="bg-yellow w-full h-[50px] absolute left-0 top-0">
                    <div
                      className="flex justify-end mt-2 mr-3 text-2xl text-black cursor-pointer"
                      onClick={() => setIsOpen2(false)}
                    >
                      X
                    </div>
                    <div className="mt-16 ml-6">
                      <h2 className="font-roboto font-semibold">{msg}</h2>
                    </div>

                    <div className="flex-row justify-center mt-24 ml-10">
                      <button
                        className="flex justify-center w-[200px] h-10 border-2 bg-black text-white  rounded-md"
                        onClick={getChecksub}
                      >
                        <h2 className="mt-1">
                          <h2 className="mt-1">{navtxt.ok[lang]}</h2>
                        </h2>
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <div className=" mt-8 py-6  mx-8">
                  <div className="bg-yellow w-full h-[50px] absolute left-0 top-0">
                    <h2 className="text-white text-xl mt-3 font-bold ml-6 font-roboto">
                      {navtxt.unsubscribe[lang]}
                    </h2>

                    <div className="mt-16 ml-6">
                      <h2 className="font-roboto font-semibold">
                        {navtxt.unsubtext[lang]}
                      </h2>
                    </div>

                    <div className="flex-row justify-center mt-7 ml-10">
                      <button
                        className="flex justify-center w-[200px] h-10 border-2 bg-black text-white  rounded-md"
                        onClick={closeModal}
                      >
                        <h2 className="mt-2">{navtxt.no[lang]}</h2>
                      </button>
                      <button
                        className="flex justify-center w-[200px] h-10 border-2 bg-gray-300 text-black rounded-md mt-4"
                        onClick={() => handleUnsub()}
                      >
                        <h2 className="mt-2">{navtxt.yes[lang]}</h2>
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>

            <Hero />
            {/**Head*/}
            {/* {currentSelectedType === "Books" && (
              <div className="mt-4">
                <h3 className="text-xl md:text-2xl font-extrabold mb-3 p-5 flex justify-center font-roboto">
                  {navtxt.trendingitems[lang]}
                </h3>
              </div>
            )}

            {currentSelectedType === "Audios" && (
              <div className="mt-4">
                <h3 className="text-l md:text-2xl font-extrabold mb-3 p-5 flex justify-center font-roboto">
                  {navtxt.Audios[lang]}
                </h3>
              </div>
            )}

            {currentSelectedType === "Videos" && (
              <div className="mt-4">
                <h3 className="text-l md:text-2xl font-extrabold mb-3 p-5 flex justify-center font-roboto">
                  {navtxt.Videos[lang]}
                </h3>
              </div>
            )} */}

            <div className="flex flex-row justify-center  w-full mt-4">
              {types.map((item, i) => (
                <div className="mt-2 w-auto" key={i}>
                  <h3
                    className={`${
                      currentSelectedType === item.en
                        ? " text-black font-extrabold border-b-4 border-yellow rounded"
                        : "text-black font-light"
                    } cursor-pointer font-roboto text-xl md:text-2xl mb-3 md:p-2 md:px-5 p-2`}
                    onClick={() => {
                      localStorage.setItem("type", item.en);
                      setCurrentSelectedType(localStorage.getItem("type"));

                      if (currentSelectedType !== "Books") {
                        if (lang === "en") {
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryEn[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryEn[0].en
                          );
                        }
                        if (lang === "fa") {
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryFa[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryFa[0].en
                          );
                        }
                        if (lang === "ps") {
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryPs[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryPs[0].en
                          );
                        }
                      }
                    }}
                  >
                    {item[lang]}
                  </h3>
                </div>
              ))}
            </div>

            {/** /Head*/}
            {/** All Categories */}
            {loading2 ? (
              <div className="flex justify-center mt-2">
                <img src={loader} className="z-10"></img>
              </div>
            ) : (
              <div className="flex md:justify-center flex-wrap mt-10">
                <div className="md:grid  md:grid-cols-3 md:gap-3 flex flex-wrap justify-center ">
                  {currentSelectedType === "Books" &&
                    lang === "en" &&
                    allCategoriesBooks &&
                    Object.keys(allCategoriesBooks).length > 0 &&
                    allCategoriesBooks.categoryEn.map((item) => {
                      // console.log(item)
                      return (
                        <div className="md:pb-2 pl-3 pb-1">
                          <Button
                            buttonName={item.enDisplay}
                            entireElement={item}
                          />
                          {console.log("item", item)}
                        </div>
                      );
                    })}
                  {currentSelectedType === "Books" &&
                    lang === "fa" &&
                    allCategoriesBooks?.categoryFa.map((item) => {
                      // console.log("fa item", item);
                      return (
                        <div className="md:pb-2 pl-3 pb-1">
                          <Button buttonName={item.fa} entireElement={item} />
                        </div>
                      );
                    })}
                  {currentSelectedType === "Books" &&
                    lang === "ps" &&
                    allCategoriesBooks?.categoryPs.map((item) => {
                      // console.log("ps item", item);

                      return (
                        <div className="md:pb-2 pl-3 pb-1">
                          <Button buttonName={item.ps} entireElement={item} 
                          />
                        </div>
                      );
                    })}
                  {currentSelectedType === "Audios" &&
                    lang === "en" &&
                    allCategoriesBooks &&
                    Object.keys(allCategoriesBooks).length > 0 &&
                    allCategoriesBooks.categoryEn.map((item) => {
                      // console.log(item)
                      return (
                        <div className="md:pb-2 pl-3 pb-1">
                          <Button
                            buttonName={item.enDisplay}
                            entireElement={item}
                          />
                          {console.log("item", item)}
                        </div>
                      );
                    })}
                  {currentSelectedType === "Audios" &&
                    lang === "fa" &&
                    allCategoriesBooks?.categoryFa.map((item) => {
                      // console.log("fa item", item);
                      return (
                        <div className="md:pb-2 pl-3 pb-1">
                          <Button buttonName={item.fa} entireElement={item} />
                        </div>
                      );
                    })}
                  {currentSelectedType === "Videos" &&
                    lang === "ps" &&
                    allCategoriesBooks?.categoryPs.map((item) => {
                      // console.log("ps item", item);

                      return (
                        <div className="md:pb-2 pl-3 pb-1">
                          <Button buttonName={item.ps} entireElement={item} />
                        </div>
                      );
                    })}
                  {currentSelectedType === "Videos" &&
                    lang === "en" &&
                    allCategoriesBooks &&
                    Object.keys(allCategoriesBooks).length > 0 &&
                    allCategoriesBooks.categoryEn.map((item) => {
                      // console.log(item)
                      return (
                        <div className="md:pb-2 pl-3 pb-1">
                          <Button
                            buttonName={item.enDisplay}
                            entireElement={item}
                          />
                          {console.log("item", item)}
                        </div>
                      );
                    })}
                  {currentSelectedType === "Videos" &&
                    lang === "fa" &&
                    allCategoriesBooks?.categoryFa.map((item) => {
                      // console.log("fa item", item);
                      return (
                        <div className="md:pb-2 pl-3 pb-1">
                          <Button buttonName={item.fa} entireElement={item} />
                        </div>
                      );
                    })}
                  {currentSelectedType === "Audios" &&
                    lang === "ps" &&
                    allCategoriesBooks?.categoryPs.map((item) => {
                      // console.log("ps item", item);

                      return (
                        <div className="md:pb-2 pl-3 pb-1">
                          <Button buttonName={item.ps} entireElement={item} />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {!loading2 && (
              <div className="max-w-screen mt-8">
                <div className="flex flex-wrap justify-center md:px-40 px-4">
                {console.log("audio---------", audios)}
                  {currentSelectedType === "Audios" && audios.length > 0
                    ? audios &&
                      audios.filter(value=>value.categoryKey===localStorage.getItem("currentCategory")).slice(0, showAudios).map((itm) => {
                        return (
                          <div className="cursor-pointer">
                            <div className="cursor-pointer">
                              <img
                                className="h-44 md:h-64 w-36 md:w-64  rounded-xl  cursor-pointer mx-2 md:mx-5  py-2"
                                alt="/"
                                src={itm.image}
                                // onClick={() => navigate(`/audio/?id=${itm.id}`)}
                                onClick={() => {
                                  const guiEvents = {};
                                  guiEvents['page'] = "audio";
                                  guiEvents['event'] = "open";
                                  // guiEvents['audio_ name'] = itm.title;
                                  guiEvents['langugae'] = localStorage.getItem("lang");
                                  SendGuiDataEvents(guiEvents);
                                  navigate(`/audio/?id=${itm.id}`);
                                }}
                              />
                              <div className="flex justify-center">
                                <p className="text-gray-800 cursor-pointer text-lg font-semibold">
                                  {/* {itm.title} */}
                                </p>
                              </div>
                            </div>
                            <div className="d:mx-4 md:w-64 font-bold md:text-xl text-base mx-2 mb-6 w-36 flex justify-center">
                              <label className="text-gray-800">
                              {itm.title.length > 30 ? `${itm.title.slice(0, 30)}...` : itm.title}
                              </label>
                            </div>
                          </div>
                        );
                      })
                    : currentSelectedType === "Audios" && (
                        <div className="flex justify-center items-center mx-40 h-40 md:h-24 md:mx-0 w-full">
                          {console.log("entered in sorry")}
                          <p className="text-gray-800 font-semibold">
                            {navtxt.comingsoon[lang]}
                          </p>
                        </div>
                      )}
                </div>
              </div>
            )}

            {!loading2 && (
              <div className="max-w-screen mt-8">
                <div className="flex flex-wrap justify-center md:px-40 px-4">
                {console.log("video---------", videos)}
                  {currentSelectedType === "Videos" && videos.length > 0 ?
                    videos &&
                    videos.filter(values=>values.category===localStorage.getItem("currentCategory")).slice(0, showVideos).map((itm) => {
                      // if (itm.lang === lang) {
                      return (
                        <div className="cursor-pointer">
                          <div className="cursor-pointer">
                            <img
                              className="h-36 md:h-64 w-36 md:w-64  rounded-xl  cursor-pointer mx-2 md:mx-5  py-2"
                              alt="/"
                              src={itm.image}
                              // onClick={() => navigate(`/video/?id=${itm.id}`)}
                              onClick={() => {
                                const guiEvents = {};
                                guiEvents['page'] = "video";
                                guiEvents['event'] = "open";
                                // guiEvents['video_ name'] = itm.title;
                                guiEvents['langugae'] = localStorage.getItem("lang");
                                SendGuiDataEvents(guiEvents);
                                navigate(`/video/?id=${itm.id}`);
                              }}
                            />
                            <div className="flex justify-center">
                              <p className="text-gray-800 cursor-pointer text-lg font-semibold">
                                {itm[lang]}
                              </p>
                            </div>
                          </div>
                          <div className="d:mx-4 md:w-64 font-bold md:text-xl text-base mx-2 mb-6 w-36 flex justify-center">
                          <label className="text-gray-800">
                              {itm.title.length > 20 ? `${itm.title.slice(0, 20)}...` : itm.title}
                              </label>
                          </div>
                        </div>
                      );
                      // }
                    })
                    : currentSelectedType === "Videos" && (
                      <div className="flex justify-center items-center mx-40 h-40 md:h-24 md:mx-0 w-full">
                        {console.log("entered in sorry")}
                        <p className="text-gray-800 font-semibold">
                          {navtxt.comingsoon[lang]}
                        </p>
                      </div>
                    )}
                </div>
              </div>
            )}
            {/** /All Categories */}
            {currentSelectedType === "Videos" && !loading2 && (
              <div className="flex justify-center">
                {videos && showVideos < videos.filter(value=>value.category===localStorage.getItem("currentCategory")).length && (
                  <button
                    className=" border rounded-md text-white font-roboto font-semibold mt-2 bg-yellow w-[80%] md:w-[25%] md:h-10 h-8"
                    onClick={showMoreVideos}
                  >
                    {navtxt.viewmore[lang]}
                  </button>
                )}
              </div>
            )}
            {currentSelectedType === "Audios" && !loading2 && (
              <div className="flex justify-center">
                {showAudios < audios.filter(value=>value.categoryKey===localStorage.getItem("currentCategory")).length && (
                  <button
                    className=" border rounded-md text-white font-roboto mt-2 font-semibold bg-yellow w-[80%] md:w-[25%] md:h-10 h-8"
                    onClick={showMoreAudios}
                  >
                    {navtxt.viewmore[lang]}
                  </button>
                )}
              </div>
            )}
            {/** All Books */}

            {!loading2 && (
              <div className="mt-8 pb-12">
                {currentSelectedType === "Books" && <BookInfo />}
              </div>
            )}
            {/** /All Books */}

            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
