import React, { useState, useEffect } from "react";
import language from "../components/language.json";

export const CommonContext = React.createContext();

export const CommonProvider = ({ children }) => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [showBooks2, setShowBooks2] = useState(4);
  const [selectedInfo, setSelectedInfo] = useState([]);

  const [langg, setLangg] = useState();
  const [currentSelectedCategory, setCurrentSelectedCategory] = useState(
    localStorage.getItem("currentCategory")
  );

  const cSTFL = localStorage.getItem("type");
  const [currentSelectedType, setCurrentSelectedType] = useState(
    cSTFL || "Books"
  );

  const [languageArr, setLanguageArr] = useState(language);

  // useEffect(() => {
  //   console.log("currentSelectedType", currentSelectedType);
  // }, [currentSelectedType]);

  // useEffect(() => {
  //   console.log("currentSelectedCategory", currentSelectedCategory);
  // }, [currentSelectedCategory]);

  function changeLanguage(value) {
    setLangg(value);
  }

  function showBooksFun(value) {
    setShowBooks2(value);
  }
  // useEffect(() => {
  //   console.log("selectedInfo", selectedInfo);
  // }, [selectedInfo]);

  // useEffect(() => {
  //   console.log("currentSelectedCategory", currentSelectedCategory);
  // }, [currentSelectedCategory]);

  // useEffect(() => {
  //   console.log("currentSelectedType", currentSelectedType);
  // }, [currentSelectedType]);

  const [allCategoriesBooks, setAllCategoriesBooks] = useState();
  const [allBooks, setAllBooks] = useState([]);

  // useEffect(() => {
  //   console.log("allCategoriesBooks", allCategoriesBooks);
  // }, [allCategoriesBooks]);

  // useEffect(() => {
  //   console.log("allBooks", allBooks);
  // }, [allBooks]);

  return (
    <CommonContext.Provider
      value={{
        isCategoryOpen,
        setIsCategoryOpen,
        currentSelectedCategory,
        setCurrentSelectedCategory,
        isTypeOpen,
        setIsTypeOpen,
        isLangOpen,
        setIsLangOpen,
        currentSelectedType,
        setCurrentSelectedType,
        languageArr,
        setLanguageArr,
        langg,
        changeLanguage,
        showBooks2,
        showBooksFun,
        selectedInfo,
        setSelectedInfo,
        allCategoriesBooks,
        setAllCategoriesBooks,
        allBooks,
        setAllBooks,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};
