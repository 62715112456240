import React, { useState, useEffect, useContext } from "react";
import Navbar from "./Navbar";
import { Link, useLocation } from "react-router-dom";
import arrow3 from "../assets/arrow3.png";
// import Logo from "../assets/Logo.png";
import AudioPlayer from "./AudioPlayer/AudioPlayer";
import { chngimg } from "./imagechng.js";
import queryString from "query-string";
import { allAudios } from "../Databases/Database";
import { CommonContext } from "../Contexts/CommonStates";
import axios from "axios";
import config from "./config";
import loader from "../assets/loading.gif";

export const TheAudio = () => {
  const lang = localStorage.getItem("lang");
  const { search } = useLocation();
  const { id } = queryString.parse(search);
  const [playTracks, setPlayTracks] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { setIsCategoryOpen, setIsTypeOpen, setIsLangOpen } =
    useContext(CommonContext);

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    fetchData(uid);
  }, []);

  async function fetchData(uid) {
    setLoading(true);
    console.log("loading.....", loading);
    const response = await axios({
      method: "post",
      url: "https://digitalweb.bngrenew.com/api/dataApi",
      data: {
        uid: uid,
        msisdn: 701701701,
        mcc: 611,
        mnc: "04",
      },
      headers: config.get("headers"),
    });
    setData(response.data);
    setLoading(false);
    console.log(
      "api data response from fetchData function  -----> ",
      response.data
    );
  }


  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    const givenTrackJsonInArr = data?.allAudios.filter((item) => item.id == id);
    // const restTracksJsonInArr = tracks.filter((item) => item.id != id);

    console.log("givenTrackJsonInArr test", givenTrackJsonInArr);

    console.log("loading....2", loading);
    // console.log("restTracksJsonInArr test", restTracksJsonInArr);

    if (givenTrackJsonInArr && givenTrackJsonInArr.length > 0) {
      // const finalArr = givenTrackJsonInArr.concat(restTracksJsonInArr);
      // console.log("finalArr test", finalArr, typeof finalArr);
      // setPlayTracks(finalArr);
      setPlayTracks([...givenTrackJsonInArr]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="">
      <div className="invisible md:visible">
        <Navbar />
      </div>
      <div
        onClick={() => {
          setIsCategoryOpen(false);
          setIsTypeOpen(false);
          setIsLangOpen(false);
        }}
      >
        <div className="flex absolute justify-start md:hidden top-0 w-screen bg-yellow">
          <Link to="/home">
            <img className="h-4 m-4" alt="/" src={arrow3} />
          </Link>
          <Link to="/home">
          <img
            className="h-10 w-56 ml-5 rounded-full m-1 cursor-pointer"
            alt="/"
            src={chngimg.img2[lang]}
            onClick={() => {}}
          /></Link>
        </div>
        <div>
          {loading ? (
            <div className="h-screen flex items-center justify-center">
              <img src={loader} className="z-10"></img>
            </div>
          ) : (
            <div className="md:mt-20 mt-28">
              {playTracks && playTracks.length> 0 && (
                <AudioPlayer tracks={playTracks} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
