import React from "react";
import loader from "../assets/loading.gif"

export const Loading = () => {
  return (
    <div className="bg-yellow flex justify-center items-center text-white rounded-lg md:w-[380px] md:h-[550px] h-[500px]">
      {/* <p className="text-white">Loading</p> */}
      <img src={loader} alt="/"/>
    </div>
  );
};
