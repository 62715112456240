import React, { useEffect, useState } from "react";
import Home from "./components/Home";
import config from "./components/config";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { TheBook } from "./components/TheBook";
import { CommonProvider } from "./Contexts/CommonStates";
import About from "./components/About";
import ContentLoading from "./components/AssetsCheck/ContentLoading";
import { TheAudio } from "./components/TheAudio";
import loader from "./assets/loading.gif";
import Unsub from "./components/Unsub";
import { TheVideo } from "./components/TheVideo";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { CommonContext } from "./Contexts/CommonStates";
import ReactGA from "react-ga4";

function App() {
  // console.log = () => {};
  console.log("app::", 1);
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const { uid, ln } = queryString.parse(search);
  const { changeLanguage } = useState(CommonContext);
  console.log("ln", uid);
  // let [searchParams, setSearchParams] = useSearchParams();
  // const uid = searchParams.get("uid");

  // const ln = searchParams.get("ln") || "en";
  // const lang = ln ? ln : lang2;
  // console.log(changeLanguage,"changelanguage");
  const lang = ln;
  // console.log("lang1::", ln);
  // console.log("uid------>", uid);
  // console.log("lang------>", lang);
  useEffect(() => {
    // console.log("local::", window.localStorage.getItem("lang"));
    if (!window.localStorage.getItem("lang") && lang) {
      window.localStorage.setItem("lang", lang);
    }
  }, [ln]);

  useEffect(() => {
    setLoading(true);
    const url = `${config.get("base")}${config.get("appConfig")}`;
    // console.log("type of----->",typeof config.get("headers"))
    fetch(url, {
      method: "POST",
      headers: config.get("headers"),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (result) => {
          setLoading(false);
          localStorage.setItem("suburl", result.subUrl);
          // console.log(result.subUrl);
          // console.log('result message::', result)
        },
        (error) => {
          console.log("error:::", error);
        }
      );
    if (uid) {
      window.localStorage.setItem("uid", uid);
    }
    // localStorage.setItem("lang","")
    // const language = localStorage.getItem("lang");
    // localStorage.setItem("lang", language ? language : "en");
    // const getVersion = localStorage.getItem("version");
    // const version = 1.0;
    // if (version !== getVersion) {
    //   localStorage.clear();
    //   localStorage.setItem("version", version);
    // }
  }, []);

  ReactGA.initialize([
    {
      trackingId: "G-KT11D6LC7F",
    },
  ]);

  return (
    <div>
      {loading ? (
        <div className="bg-yellow flex h-screen justify-center items-center text-white">
          {/* <div>Loading</div> */}
          <img src={loader} alt="/" />
        </div>
      ) : (
        <div>
          <CommonProvider>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/book" element={<TheBook />} />
              <Route path="/about" element={<About />} />
              <Route path="/audio" element={<TheAudio />} />
              <Route path="/video" element={<TheVideo />} />
              <Route path="/unsub" element={<Unsub />} />
              <Route path="/contentLoading" element={<ContentLoading />} />

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </CommonProvider>
        </div>
      )}
    </div>
  );
}

export default App;
