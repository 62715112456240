// import React from 'react'

export const chngimg={
    img1:{
    //    en:'banner_1',
    //    ps:'Pashtobanner',
    //    dr:'Daribanner',/
       en:require("../assets/web_assets/banner1.png"),
       ps:require("../assets/web_assets/Pashtobanner.png"),
       fa:require("../assets/web_assets/Daribanner.png"),
       
    },
    img2:{
        en:require("../assets/Logo.png"),
       ps:require("../assets/Pashtologo.png"),
       fa:require("../assets/Darilogo.png"),
    }

}