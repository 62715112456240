import React from "react";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";

const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
  isReady,
}) => (
  <div className="audio-controls mt-5">
    <button
      type="button"
      className="prev"
      aria-label="Previous"
      onClick={onPrevClick}
    >
      {/* <Prev /> */}
      <BsIcons.BsFillSkipBackwardFill />
    </button>
    {isPlaying ? (
      <button
        type="button"
        className="pause"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      >
        {/* <Pause /> */}
        <FaIcons.FaPause />
        {/* <BsIcons.BsFillPauseFill /> */}
      </button>
    ) : (
      <button
        type="button"
        className="play"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      >
        {/* <Play /> */}
        <FaIcons.FaPlay />
      </button>
    )}
    <button
      type="button"
      className="next"
      aria-label="Next"
      onClick={onNextClick}
    >
      {/* <Next />  */}
      <BsIcons.BsSkipForwardFill />
    </button>
  </div>
);

export default AudioControls;
