import React from "react";
// import Logo from "../assets/Logo.png";
import Footer from "./Footer";
import unsub from "../assets/unsubscribe.png";
import config from "./config";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import loader from "../assets/loading.gif";
import Modal from "react-modal";
import { navtxt } from "./text.js";
import { chngimg } from "./imagechng.js";
import info from "../assets/Info.png";

const Unsub = () => {
  const [msg, setMsg] = useState("");
  const lang = localStorage.getItem("lang");
  const uid = window.localStorage.getItem("uid");
  const navigate = useNavigate();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.9)",
    },
    content: {
      top: "40%",
      left: "20%",
      height: "400px",
      width: "280px",
      // right: 'auto',
      // bottom: 'auto',
      transform: "translate(-50%, -50%)",

      color: "#3d3d3d",
      outline: "none",
      marginTop: "10%",
      marginLeft: "32%",
      padding: "0px",
    },
  };

  const checksub = (uid) => {
    setLoading(true);
    const url = `${config.get("base")}${config.get("checkSub")}`;
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ uid: uid }),
      headers: config.get("headers"),
    })
      // console.log("checksub req",uid)
      .then((res) => {
        // console.log(res)
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (result) => {
          // console.log('result message::', result)
          // console.log("uid is set", uid);

          if (result.status.toUpperCase() === "FAILED") {
            const sub = localStorage.getItem("suburl");
            sub && window.location.replace(sub, "_self");
            // console.log("entered in then. result");
          } else if (result.status.toUpperCase() === "SUCCESS") {
            navigate("/home");
          }
        },
        (error) => {
          console.log("error:::", error);
        }
      );
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    // console.log("model opened");
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }


  const [loading, setLoading] = useState(false);

  const handleUnsub = () => {
    const uid = localStorage.getItem("uid");
    setLoading(true);
    // console.log(
    //   "Unsub called"
    // )
    const url = `${config.get("base")}${config.get("unsubscribe")}`;
    console.log("unsub req : ", JSON.stringify({ uid: uid }));

    fetch(url, {
      method: "POST",
      body: JSON.stringify({ uid: uid }),
      headers: config.get("headers"),
    })
      // console.log("checksub req",uid)
      .then((res) => {
        // console.log(res)
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (result) => {
          // console.log('result message::', result)
          console.log("uid is set", uid);
          setLoading(false);

          if (result.status.toUpperCase() === "FAILED") {
            // const sub = localStorage.getItem("suburl");
            // sub && window.location.replace(sub, "_self");
            // console.log("entered in then. result");
            // msg = result.message;
            setMsg(result.message);

            openModal();
            // console.log(msg);
          }
          if (result.status.toUpperCase() === "SUCCESS") {
            // checksub(uid);
            // var msg= result.message;
            // console.log(msg);
            setMsg(result.message);
            openModal();
          }
        },
        (error) => {
          console.log("error:::", error);
        }
      );
  };

  return (
    <>
      {loading ? (
        <div className="bg-yellow flex h-screen justify-center items-center text-white">
          {/* <div>Loading</div> */}
          <img src={loader}></img>
        </div>
      ) : modalIsOpen ? (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className=" mt-8 py-6  mx-8">
            <div className="bg-yellow w-full h-[50px] absolute left-0 top-0">
            <Link to="/home">
             <div className="flex justify-end mt-2 mr-3 text-2xl text-black cursor-pointer">X</div></Link>
              <div className="flex justify-center mt-20 h-24">
                <img src={info} alt="/"></img>
              </div>
              <div className="mt-10 flex justify-center">
                <h2 className="font-roboto font-semibold px-1">{msg}</h2>
              </div>

              <div className="flex-row justify-center mt-10 ml-10">
                <Link to="/home">
                  <button className="flex justify-center w-[200px] h-10 border-2 bg-black text-white  rounded-md">
                    <h2 className="mt-1">
                      <h2 className="mt-1">{navtxt.ok[lang]}</h2>
                    </h2>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <div>
          <div className="h-12 w-full bg-yellow flex mt-0 justify-center">
            <img
              className="h-10 w-56 rounded-full mt-1"
              src={chngimg.img2[lang]}
              alt="/"
            ></img>
          </div>
          <div className="mt-20 flex justify-center">
            <img src={unsub} alt="/"></img>
          </div>
          <div className="mt-10 flex justify-center">
            <h2 className="text-yellow text-xl font-bold  font-roboto">
              {navtxt.unsubscribe[lang]}
            </h2>
          </div>
          <div className="mt-10 flex justify-center">
            <h2 className="font-roboto font-semibold">
              {navtxt.unsubtext[lang]}
            </h2>
          </div>
          <div className="flex-row justify-center mt-10 ml-16">
            <Link to="/home">
              {" "}
              <button className=" w-[250px] h-10 border-2 bg-black text-white  rounded-md">
                <h2 className="mt-1">{navtxt.no[lang]}</h2>
              </button>
            </Link>
            <button
              className=" w-[250px] h-10 border-2 bg-gray-300 text-black rounded-md mt-4"
              onClick={() => handleUnsub()}
            >
              <h2 className="mt-1">{navtxt.yes[lang]}</h2>
            </button>
          </div>

          <div className="mt-[123px]">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Unsub;
