import React from "react";

const Footer = () => {
  return (
    <div className="bg-black py-4 fixed bottom-0 w-screen z-10 ">
      {" "}
      {/* fixed bottom-0 w-full z-10 */}
      <h1 className="flex justify-center text-xs text-white font-roboto">
        @2023 MTN EDUCATION PORTAL . All Rights Reserved
      </h1>
    </div>
  );
};
export default Footer;
