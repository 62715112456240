

import React, { useState, useEffect } from "react";


const MyComponent = () => {
  useEffect(() => {
    apiCall();
  }, []);

  const [result,setResult] = useState();
  const [resultaudio,setResultaudio] = useState();
  const [resultvideo,setResultvideo] = useState();

  const apiCall = () => {
    const url = "https://digitalweb.bngrenew.com/api/urlParsing"
    // Your API call logic here
    // ...

    // Replace the fetch call with your actual API request
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        uid: "4f1a63f9",
        msisdn: 701701701,
        mcc: 611,
        mnc: "04",
      }),
      headers: {
        Username: "web",
        Servicefor: "mtnEduPortal",
        Language: "en",
        Reqfrom: "web",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (res) => {
          setResult(res.image);
          setResultaudio(res.audio);
          setResultvideo(res.video);
          // resultpdf = res.pdf;
          checkFound(result);
          checkFoundAudio(res.audio);
          checkFoundVideo(res.video);
          // checkFoundPdf(res.pdf);
        },
        (error) => {
          console.log("error:::", error);
        }
      );
  };

  const checkFound = (result) => {
    result && result.map((item) => {
      checkStatus(item);
    });
  };

  const checkFoundAudio = (resultaudio) => {
    resultaudio && resultaudio.map((item) => {
      checkStatusAudio(item);
    });
  };

  const checkFoundVideo = (resultvideo) => {
    resultvideo && resultvideo.map((item) => {
      checkStatusVideo(item);
    });
  };

  // const checkFoundPdf = (result) => {
  //   resultpdf.map((item) => {
  //     checkStatusPdf(item);
  //   });
  // };

  const checkStatus = (link) => {
    console.log(link);
    checkIfImageExists(link, (exists) => {
      if (!exists) {
        const box = document.createElement("div");
        box.classList = "links";
        box.innerText = link;
        document.body.appendChild(box);
      }
    });
  };

  const checkStatusAudio = (linkAudio) => {
    checkIfAudioExists(linkAudio, (exists) => {
      if (!exists) {
        const box = document.createElement("div");
        box.classList = "links";
        box.innerText = linkAudio;
        document.body.appendChild(box);
      }
    });
  };

  const checkStatusVideo = (linkVideo) => {
    checkIfVideoExists(linkVideo, (exists) => {
      if (!exists) {
        const box = document.createElement("div");
        box.classList = "links";
        box.innerText = linkVideo;
        document.body.appendChild(box);
      }
    });
  };

  // const checkStatusPdf = (linkPdf) => {
  //   checkIfPdfExists(linkPdf, (exists) => {
  //     if (!exists) {
  //       const box = document.createElement("div");
  //       box.classList = "links";
  //       box.innerText = linkPdf;
  //       document.body.appendChild(box);
  //     }
  //   });
  // };

  const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  };

  const checkIfAudioExists = (url, callback) => {
    const audio = new Audio();
    audio.src = url;

    if (audio.complete) {
      callback(true);
    } else {
      audio.onload = () => {
        callback(true);
      };

      audio.onerror = () => {
        callback(false);
      };
    }
  };

  const checkIfVideoExists = (url, callback) => {
    const video = document.createElement("video");
    video.src = url;

    if (video.readyState >= 4) {
      callback(true);
    } else {
      video.oncanplaythrough = () => {
        callback(true);
      };

      video.onerror = () => {
        callback(false);
      };
    }
  };

  return (
    <div>
      <div className="heading">Missing</div>
      {/* Add your JSX content here */}
    </div>
  );
};

export default MyComponent;

